import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styled from '@emotion/styled'

import BGImg from 'gatsby-background-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhoneAlt,
  faEnvelope,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons'
import { Darkener } from '../../core/styles'

import colors from '../../../config/colors'
import constants from '../../../config/constants'
import spacings from '../../../config/spacings'
import { sheets, fontSizes } from '../../../config/typography'

function Contact() {
  const {
    contactUsImage: {
      childImageSharp: { fluid: contactUsFluid },
    },
  } = useStaticQuery(graphql`
    query {
      contactUsImage: file(relativePath: { eq: "contact-us-il.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <HeadingWrapper fluid={contactUsFluid}>
        <h1>Entre em contato conosco</h1>
        <Darkener intensity="0.6" />
      </HeadingWrapper>
      <ContentWrapper>
        <CoreElement>
          <IconContainer>
            <StyledIcon icon={faPhoneAlt} />
          </IconContainer>
          <h3>Telefone</h3>
          <p>
            <p>+55 (31) 3568-5412</p>
            <p>+55 (31) 99601-6800</p>
          </p>
        </CoreElement>
        <CoreElement>
          <IconContainer>
            <StyledIcon icon={faEnvelope} />
          </IconContainer>
          <h3>E-mail</h3>
          <p>
            <p>contato@leliseng.com</p>
          </p>
        </CoreElement>
        <CoreElement>
          <IconContainer>
            <StyledIcon icon={faMapMarkerAlt} />
          </IconContainer>
          <h3>Localização</h3>
          <p>
            <p>Belo Horizonte - MG, Brasil</p>
          </p>
        </CoreElement>
      </ContentWrapper>
    </Container>
  )
}

export default Contact

const Container = styled.main`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeadingWrapper = styled(BGImg)`
  width: 100%;
  height: 300px;

  padding: ${spacings.large}rem;

  position: relative;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;

  & h1 {
    color: ${colors.dark.headers};
    text-align: center;
  }
`

const ContentWrapper = styled.section`
  flex-grow: 1;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  max-width: ${constants.sizes.maxWidth}px;
  padding: ${spacings.large}rem;

  @media only screen and (max-width: ${constants.sizes.medium}px) {
    grid-template-columns: 1fr;
  }
`

const CoreElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${spacings.large}rem ${spacings.default}rem;

  color: ${colors.light.text};

  & > h3 {
    margin: ${spacings.default}rem 0;
  }

  & > p {
    margin: ${spacings.default}rem 0;
    ${sheets.default.default};
    text-align: center;

    & > p {
      margin: ${spacings.default}rem 0;
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${fontSizes.largest * (8 / 9)}rem;
  height: ${fontSizes.largest * (8 / 9)}rem;
  padding: ${spacings.medium}rem;
  box-sizing: content-box;

  border-radius: 50%;
  background: ${colors.dark.background};
`

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: ${fontSizes.largest * (8 / 9)}rem;
  color: ${colors.dark.text};
`
