import React from 'react'
import DefaultMaster from '../components/masters/DefaultMaster/DefaultMaster'
import Contact from '../components/containers/Contact'

function AboutPage() {
  return (
    <DefaultMaster title="Contato">
      <Contact />
    </DefaultMaster>
  )
}

export default AboutPage
